import React from "react";

import "./about.css";

const About = () => {
  return (
    <div id="about" className="about-container">
      <h2>About</h2>
      <p>
        I'm a software developer from Melbourne, Australia. I spend my free time travelling, working out, and keeping up with the latest developments in the space industry.
      </p>
      <p>
        I'm interested in full-stack development, with frontend experience working mostly in React, as well as backend development in Node.js. I recently received my AWS Certified Developer Associate certificate, and enjoy building out new projects in AWS.
      </p>
    </div>
  );
};

export default About;
