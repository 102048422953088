import React from "react";

import Sidebar from "../../components/sidebar/sidebar";
import ResumePDF from "../../components/resume/resumepdf";

import "./resume.css";

const Resume = () => {
  return (
    <div>
      <div className="resume">
        <Sidebar />
        <ResumePDF />
      </div>
    </div>
  );
};

export default Resume;
