import React from "react";

import "./certificates.css";

const Certificates = () => {
  return (
    <div id="certificates" className="certificates">
      <h2>Certificates</h2>
      <div className="cert-contianer">
        <a href="https://www.credly.com/badges/e65e9776-bd81-44d7-a7c3-247b5c9f704d/public_url" target="_blank" rel="noreferrer">
          <img src="/img/dva-c02.png" />
        </a>
        <div className="cert-text-container">
          <p className="cert-name">AWS Certified Developer – Associate</p>
          <p>Issued on March 31, 2023</p>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
