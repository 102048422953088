import React from "react";

import Project from "./components/project";

import "./projects.css";

const Projects = () => {
  return (
    <div id="projects" className="projects-container">
      <h2>Projects</h2>
      <div className="projects-list">
        <Project
          title={"Sensor Dashboard"}
          imageURL={"/img/sensordashboard.png"}
          description={
            "A simple UI dashboard to display data from WiFi-enabled Arduino sensors. Hosted on S3, with data stored in DynamoDB, accessed through an API Gateway connected to Lambda functions."
          }
          github={"https://github.com/aidanbeale/sensor-dashboard"}
          website={""}
        />
        <Project
          title={"Snake Game"}
          imageURL={"/img/snakegame.png"}
          description={
            "The well-known 'snake' game. Built in React, with Redux for state management. Hosted in AWS, with a Hiscores database stored in DynamoDB, accessed through an API Gateway connected to Lambda functions."
          }
          github={"https://github.com/aidanbeale/snake-react"}
          website={"http://snake.aidanbeale.com"}
        />
        <Project
          title={"VisitHop"}
          imageURL={"/img/visithop.png"}
          description={
            "Helping people find nearby cities to visit. Frontend in React + Redux, with backend API in Lambda and DynamoDB."
          }
          github={"https://github.com/aidanbeale/visithop-react-app"}
          website={"https://visithop.com "}
        />
        <Project
          title={"Blockchain Capstone Project"}
          imageURL={"/img/blockchain.png"}
          description={
            "A POC supply chain tracking system, proving provenance and location of products through a bluetooth beacon and blockchain transactions. Android apps for user interface with Node.js web servers hosted on AWS."
          }
          github={"https://github.com/SwinburneBlockchain"}
          website={""}
        />
      </div>
    </div>
  );
};

export default Projects;
