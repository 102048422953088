import React from "react";

import Header from "../header/header";

import "./resumepdf.css";

const ResumePDF = () => {
  return (
    <div>
      <Header />
      <object className="resume-pdf" data="https://aidanbeale-resume.s3.ap-southeast-2.amazonaws.com/resume.pdf" type="application/pdf" width="100%" height="100%">
        <p className="pdf-alt">Your web browser doesn't have a PDF plugin.
        Instead you can <a href="https://aidanbeale-resume.s3.ap-southeast-2.amazonaws.com/resume.pdf">click here to
        download the PDF file.</a></p>
      </object>
    </div>
  );
};

export default ResumePDF;
